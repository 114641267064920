import axios from "axios";

export default {
  email(payload) {
    return axios.post("user/change_email", payload);
  },

  role(id, role) {
    return axios.put(`lighthouse/settings/account/${id}/update_role`, role);
  },

  slack(id, payload) {
    return axios.put(
      `lighthouse/settings/account/${id}/update_slack_id`,
      payload
    );
  },

  access(id, access) {
    return axios.put(`lighthouse/settings/account/${id}/update_access`, access);
  },

  invite(payload) {
    return axios.post(`lighthouse/settings/resend_invite`, payload);
  },

  reset(id) {
    return axios.put(`lighthouse/settings/account/${id}/update_password`);
  },

  delete(email, payload) {
    return axios.delete(`customer/${email}`, { data: payload });
  },
};
