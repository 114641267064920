<template>
  <eden-confirm-dialog
    title="Reset Customer Password"
    button-text="Reset"
    button-type="success"
    :show.sync="shouldShow"
    @confirm="reset"
  >
    <p>
      Are you sure you want to reset
      <strong class="text-grey-primary">{{ customerName }}'s </strong> password?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import member from "@/requests/settings/members/member";

export default {
  name: "CustomerPasswordReset",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    reset() {
      member
        .reset(this.customer.id)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss"></style>
